import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { gamesApi } from '../../lib/api/cms/gamesService/Games';
import { setActiveGamesCategory } from '../../redux/actions/gamesActions/gamesAction';

export const GamesCache = new Map();

export const GamesKeys = {
  topGames: 'top-games',
  recommendedGames: 'recommended-games',
  popularGames: 'popular-games',
  newGames: 'new-games',
};

export function useFetchGames(categoryKey, recommendationType, partyId, enabled) {
  const [response, setResponse] = useState();
  const cacheKey = JSON.stringify([categoryKey, partyId]);
  let normalizedGames = GamesCache.get(cacheKey);
  // eslint-disable-next-line
  const [state, setState] = useState(!enabled ? 'error' : !!normalizedGames ? 'idle' : 'loading');
  const dispatch = useDispatch();

  useEffect(() => {
    if (!enabled || state === 'error' || normalizedGames) return;

    const getData = async () => {
      setState('loading')
      let fetchResponse = {};

      try {
        dispatch(setActiveGamesCategory({ [categoryKey]: true }));

        if (!normalizedGames) {
          fetchResponse = await gamesApi.getRecommendedGames({
            recommendationType,
            playerID: partyId,
          });

          if (!fetchResponse?.data?.items?.length || fetchResponse?.data?.items?.length < 6) {
            setState('error');
            return;
          }

          const hasMoreThanGamesLimit = fetchResponse?.data?.items?.length >= 12;
          // eslint-disable-next-line
          normalizedGames = fetchResponse.data.items.slice(0, hasMoreThanGamesLimit ? 12 : 6);

          if (!normalizedGames?.length) {
            setState('error');
            return;
          }

          GamesCache.set(cacheKey, normalizedGames);
        }

        setResponse({
          ...fetchResponse?.data,
          normalizedGames,
        });
        setState('idle');
      } catch (error) {
        setState('error');
      }
    };

    getData();

  }, [partyId, enabled, dispatch, categoryKey, recommendationType, state, response, cacheKey]);

  useEffect(() => {
    if (state === 'error') {
      dispatch(setActiveGamesCategory({ [categoryKey]: false }));
    }
  }, [state, dispatch, categoryKey]);

  return { games: normalizedGames, state, response };
}
