/* eslint-disable import/prefer-default-export */

import { useFetchGames, GamesKeys } from './useFetchGames';
import usePlayer from '../usePlayer';
import { getSessionCookie } from '../../components/Helpers';

export function useGetTopGames() {
  const { player } = usePlayer();
  const session = getSessionCookie();
  const partyId = session?.partyId ?? player?.partyId;
  const hasPartyID = !!partyId;

  return useFetchGames(
    GamesKeys.topGames,
    hasPartyID ? 'players_like_you' : 'new_player_recommendations',
    partyId,
    !!player?.partyId,
  );
}
